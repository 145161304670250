import React from 'react';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';

import OrgSelector from './OrgSelector';
import NewFidelityDialog from './NewFidelityDialog';

import { getFidelities } from '../../../actions/controlpanel/fidelity/getFidelities';
import { deleteFidelity } from '../../../actions/controlpanel/fidelity/deleteFidelity';
import { groups } from '../../../settings.json'

const DEBUG = false;

class FidelityPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seletedOrg: null,
            cards: null,
            redirectPath: null,
            isAdmin: false,
            newFidelityDialogOpen: false,
            loggedIn: Cookies.get('session') ? true : false,
        };
    }

    columns = [
        { field: 'barcode', headerName: 'Codice', flex: 5 },
        { field: 'name', headerName: 'Nome', flex: 5 },
        { field: 'phone', headerName: 'Telefono', flex: 5 },
        {
            field: 'delete',
            headerName: '',
            filterable: false,
            disableColumnMenu: true,
            hideSortIcons: true,
            disableReorder: true,
            align: 'center',
        },
    ];

    CustomGridBar(props) {
        return (
            <>
                <GridToolbarFilterButton />
                <Button onClick={() => props.onNewFidelityOpen()}>Nuovo</Button>
            </>
        )
    }

    componentDidMount() {
        const isAdmin = groups.controlpanel.readWrite.includes(jwt.decode(Cookies.get('session')).role);
        if (isAdmin) {
            this.columns[3].renderCell = (params) => (<Button onClick={() => this.deleteFidelity(params.row.barcode)} variant='contained' color='error' size='small'><Delete /></Button>);
        }
    }

    deleteFidelity(barcode) {
        deleteFidelity(barcode, this.state.seletedOrg);
        const cards = this.state.cards.filter((card) => card.barcode !== barcode);
        this.setState({cards});
    }

    async componentDidUpdate() {
        if (this.state.seletedOrg && !this.state.cards) {
            this.setState({
                cards: await getFidelities(this.state.seletedOrg),
            });
        }
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />

        return (
            <>
                <Switch>
                    <Route path={`${this.props.match.url}/`}>
                        <Container maxWidth='md'>
                            <OrgSelector onOrgChange={(org) => this.setState({ seletedOrg: org })} />
                            {this.state.cards ?
                                <DataGrid
                                    components={{
                                        Toolbar: this.CustomGridBar,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            onNewFidelityOpen: () => this.setState({ newFidelityDialogOpen: true }),
                                        }
                                    }}
                                    rows={this.state.cards}
                                    columns={this.columns} autoHeight
                                /> :
                                null}
                        </Container>
                        <NewFidelityDialog
                            open={this.state.newFidelityDialogOpen}
                            orgId={this.state.seletedOrg}
                            onClose={() => this.setState({ newFidelityDialogOpen: false })}
                        />
                    </Route>
                </Switch>

                {DEBUG ? <Typography>{JSON.stringify(this.state)}</Typography> : null}
            </>
        )
    }
}



export default FidelityPanel;