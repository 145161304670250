import React from 'react';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';

import doLogout from '../../actions/login/logout';
import { Redirect } from 'react-router';
import { styled } from '@mui/system';

const LogoutBtn = styled(Button)(({ theme }) => ({
    marginLeft: '2rem',
    backgroundColor: 'lightgray',
    color: 'black',
    '&:hover': {
        backgroundColor: 'gray',
        color: 'white',
    }
  }));

  const SelectorBtn = styled(Button)(({ theme }) => ({
    marginLeft: '2rem',
    backgroundColor: 'lightgray',
    color: 'black',
    '&:hover': {
        backgroundColor: 'gray',
        color: 'white',
    }
  }));


class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            loggedIn: Cookies.get('session') ? true : false,
            redirect: null,
        }
    }

    componentDidMount() {
        const token = jwt.decode(Cookies.get('session'));
        this.setState({ fullName: token.fullName });
    }

    onLogoutClick() {
        doLogout();
        this.setState({ loggedIn: false });
    }

    onSelectorClick() {
        this.setState({redirect: 'selector'});
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />
        if (this.state.redirect === 'selector') return <Redirect to='/selector' />

        return (
            <>
                <AppBar position='static'>
                    <Toolbar variant='dense' >
                        <Typography variant='h6' sx={{flexGrow: 1}}>{new Date(this.props.projStartDate).toLocaleString('it-IT', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'})}</Typography>
                        <Typography variant='h6' >{this.state.fullName}</Typography>
                        <SelectorBtn variant="contained" onClick={() => this.onSelectorClick()}>
                            Elenco
                        </SelectorBtn>
                        <LogoutBtn variant="contained" onClick={() => this.onLogoutClick()}>
                            Logout
                        </LogoutBtn>
                    </Toolbar>
                </AppBar>
            </>
        )
    }


}

export default Login;