import React from 'react';
import { TextField, Typography } from '@mui/material';

const DEBUG=false;

const styles = {
    marginTop: {
        marginTop: '1rem',
    },
}

class NamePhone extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            phone: null,
            currentState: null,
        };
        this.cleanState = this.state;
        this.focussableInputs = {
            name: React.createRef(),
            phone: React.createRef(),
        }
    }

    sendUser(compiled) {
        const userData = {
            name: this.state.name,
            phone: this.state.phone,
        }
        this.props.onDataReturned(userData, compiled);
    }

    onKeyDown(e) {
        if(e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            switch (this.state.currentState) {
                case 'name':
                    this.setState({currentState: 'phone'});
                    break;
                case 'phone':
                    this.sendUser(true);
                    break;
                default:
                    this.setState({currentState: 'name'});
                    break;
            }
        }
    }

    onChange(e, source) {
        const arr = [];
        arr[source] = e.target.value.toLocaleUpperCase('it-IT');
        this.setState(arr, () => this.sendUser(false));
    }

    onFocus(e, source){
        this.setState({currentState: source})
        this.props.onFocus();
    }

    handleReset() {
        this.setState(this.cleanState);
        this.props.confirmReset();
    }

    componentDidUpdate() {
        if(this.props.resetState === 'full') this.handleReset();
        if(this.props.currentlyActive && !this.state.currentState) this.setState({currentState: 'name'});
        if(this.props.currentlyActive && this.state.currentState === 'compiled') this.sendUser();
        if(this.state.currentState === 'send') this.sendUser();
    }

    render() {
        return (
            <>
                <TextField fullWidth
                    variant='outlined'
                    sx={styles.marginTop}
                    value={this.state.name || ''}
                    /*onChange={(e) => this.setState({name: e.target.value.toLocaleUpperCase('it-IT')})}*/
                    onChange={(e) => this.onChange(e, 'name')}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onMouseDown={() => this.setState({currentState: 'name'})}
                    onFocus={(e) => this.onFocus(e, 'name')}
                    inputRef={input => input && this.props.currentlyActive && this.state.currentState === 'name' && !(document.activeElement === input)? input.focus():null}
                    label='Nome e Cognome' />
                <TextField fullWidth
                    variant='outlined'
                    sx={styles.marginTop}
                    value={this.state.phone || ''}
                    onChange={(e) => this.onChange(e, 'phone')}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onMouseDown={() => this.setState({currentState: 'phone'})}
                    onFocus={(e) => this.onFocus(e, 'phone')}
                    inputRef={input => input && this.props.currentlyActive && this.state.currentState === 'phone' && !(document.activeElement === input)? input.focus():null}
                    label='Telefono' />
                
                {DEBUG? <Typography>{JSON.stringify(this.state)}</Typography>: null}
                
            </>
        )
    }

}

export default NamePhone;