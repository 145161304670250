import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../settings.json';


async function createProjection(cinemaId, screenId, startDate) {
    try {
        const projection = await axios.post(`${api.endpoint}/cinemas/${cinemaId}/${screenId}/projections`,
        {
            startDate,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('session')}`
            }
        });
        return projection.data;
    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
        if(e.response.status === 403) throw new Error(-2);
    }
    
}

export default createProjection