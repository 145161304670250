import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../settings.json';


async function saveTicketData(projId, seat, customer) {
    try {
        const projectionData = await axios.post(`${api.endpoint}/projections/${projId}/customers`,
        {
            seat,
            customer,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('session')}`,
            }
        });
        return projectionData.data;

    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
        if(e.response.status === 403) throw new Error(-2);
        if(e.response.status === 500) throw new Error(-3);
    }
    
}

export default saveTicketData;