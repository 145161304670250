import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../settings.json';


async function getProjections(cinemaId, screenId) {
    try {
        const rawProjections = await axios.get(`${api.endpoint}/cinemas/${cinemaId}/${screenId}/projections`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` } });
        if((!Array.isArray(rawProjections.data) || !rawProjections.data.length)) return [{id: -1, name: 'Nessuna proiezione disponibile', disabled: true}];
        const projections = rawProjections.data.map(projection => {
            const projDate = new Date(projection.startDate);
            return {
                id: projection.id,
                name: projDate.toLocaleString('it-IT', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}),
            }
        });
        return projections;
    } catch (e) {
        if (e.response.status === 401) throw new Error(-1);
        if (e.response.status === 403) throw new Error(-2);
    }

}

export {
    getProjections,
}