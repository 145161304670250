import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
}

class ErrorPopup extends React.Component {

    render() {
        return (
            <>
                <Dialog open={this.props.enabled} >
                    <DialogTitle >Login non riuscito</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.props.error}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.onClose()} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }


}

export default withStyles(styles)(ErrorPopup);