import React from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { getCustomers } from '../../../../actions/controlpanel/projections/view/getCustomers';

const DEBUG = false;

class ViewPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: null,
            loggedIn: Cookies.get('session') ? true : false,
        };
    }

    columns = [
        { field: 'seat', headerName: 'Posto', flex: 5 },
        { field: 'customer', headerName: 'Nominativo', flex: 5 },
        { field: 'timestamp', headerName: 'Ora di ingresso', flex: 3 },
    ];

    async componentDidMount() {
        this.setState({
            customers: await getCustomers(this.props.match.params.projId),
        });
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />
        return (
            <>
                <Container maxWidth='md'>
                    {this.state.customers ?
                        <DataGrid
                            rows={this.state.customers}
                            columns={this.columns} autoHeight
                        /> :
                        null}
                </Container>
                {DEBUG ? <Typography>{JSON.stringify(this.state)}</Typography> : null}
                {DEBUG ? <Typography>{JSON.stringify(this.props.match.params.projId)}</Typography> : null}
            </>
        )
    }
}

export default ViewPanel;