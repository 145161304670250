import axios from 'axios';
import Cookies from 'js-cookie';

import {
    api
} from '../../../../settings.json';


async function saveFidelity(barcode, data, orgId) {
    try {
        const fidelityData = await axios.post(`${api.endpoint}/organizers/${orgId}/fidelity`,
        {
            barcode,
            name: data.name,
            phone: data.phone,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('session')}`
            }
        });
        return fidelityData.data.data;

    } catch (e) {
        if(e.response.status === 443) window.alert('Codice già presente')
    }

}

export {
    saveFidelity,
};