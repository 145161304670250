import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { TextField } from '@mui/material';

class ProjectionBuilder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectionDate: null,
        };
    }

    setProjectionDate(projectionDate) {
        this.setState({ projectionDate });
        this.props.onNewProjection(projectionDate)
    }

    render() {
        return (
            <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        value={this.state.projectionDate}
                        ampm={false}
                        inputFormat='dd/MM/yyyy HH:mm'
                        label='Data e ora'
                        onChange={(date) => this.setProjectionDate(date)}
                    />
                </LocalizationProvider>
            </>
        )
    }


}

export default ProjectionBuilder;