import React from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';


import { getScreens } from '../../actions/selector/getScreens';



class ScreenSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            screenOpen: false,
            screenOptions: [],
        };
    }

    async updateScreens() {
        this.setState({ screenOptions: await getScreens(this.props.cinemaId) });
    }

    setScreen(screen) {
        this.props.onScreenChange(screen? screen.id: null)
    }

    render() {
        const screenLoading = this.state.screenOpen && this.state.screenOptions.length === 0;
        return <>
            <Autocomplete
                open={this.state.screenOpen}
                onOpen={() => {
                    this.setState({ screenOpen: true });
                    this.updateScreens();
                }}
                onClose={() => {
                    this.setState({ screenOpen: false });
                }}
                onChange={(event, screen) => this.setScreen(screen)}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={this.state.screenOptions}
                loading={screenLoading}
                loadingText='Caricamento...'
                disabled={!this.props.cinemaId}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Sala"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {screenLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
                    

        </>;
    }


}

export default ScreenSelector;