import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

import { getTicket } from '../../../../actions/accesscontrol/validators/18tickets/api18tickets';
import TicketPopup from './TicketPopup';

const DEBUG=false;

const styles = {
    marginTop: {
        marginTop: '1rem',
    },
    centerBox: {
        justifyContent: "center",
        alignItems: "center"
    }
}


class Validator18Tickets extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentQrCode: null,
            ticketQrCode: null,
            title: null,
            fare: null,
            startTime: null,
            screen: null,
            seat: null,
            valid: null,
            ticketStatus: null,
            showError: false,
            statusDescription: null,
            currentState: 'qr',
        };
        this.cleanState = this.state;
    }

    async onTicketSubmit() {
        if (!this.state.currentQrCode) {
            if (!this.state.ticketQrCode) return;
            else {
                this.sendSeat(true);
                return;
            }
        }
        const ticketData = await getTicket(this.state.currentQrCode, this.props.theatreId);
        this.setState({
            currentQrCode: null,
            ticketQrCode: this.state.currentQrCode,
            title: ticketData.film,
            fare: ticketData.rate,
            startTime: ticketData.projection_start,
            screen: ticketData.theater,
            seat: this.formatSeat(ticketData.seat),
            valid: ticketData.granted ? 'SI' : 'NO',
            showError: !ticketData.granted,
            statusDescription: ticketData.reason,
            ticketStatus: ticketData.status,
        });
        this.sendSeat(ticketData.granted);
    }

    onTicketPopupClose(status) {
        this.setState({
            showError: false,
            ticketStatus: status,
        });
        if (status === 'confirm_online_reduction') {
            this.confirmOnlineReduction();
        }
    }

    onKeyDown(e) {
        if(e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            this.onTicketSubmit()
        }
    }

    onFocus(e){
        if (!this.props.currentlyActive) this.props.onFocus();
    }

    sendSeat(compiled) {
        this.props.onDataReturned({ seat: this.formatSeat(this.state.seat), qr: this.state.ticketQrCode }, compiled);
    }

    async confirmOnlineReduction() {
        await this.onTicketSubmit();
    }

    componentDidUpdate() {
        if (this.props.resetState) this.handleReset();
        if (this.props.currentlyActive && !this.state.currentState) this.setState({currentState: 'qr'});
    }

    handleReset() {
        this.setState(this.cleanState);
        this.props.confirmReset();
    }

    cropString(str, len) {
        if(!str) return null;
        return str.length > len ? str.substring(0, len-3) + "..." : str;
    }

    render() {
        return (
            <>
                <TextField fullWidth
                    variant='outlined'
                    sx={styles.marginTop}
                    onChange={(e) => this.setState({ currentQrCode: e.target.value })}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onFocus={(e) => this.onFocus(e)}
                    value={this.state.currentQrCode || ''}
                    inputRef={input => input && this.props.currentlyActive? input.focus():null}
                    label='Codice QR Biglietto' />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={styles.marginTop} variant='body1'>Codice</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={styles.marginTop} variant='body1'>{this.state.ticketQrCode}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={styles.marginTop} variant='h5'>Titolo</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={styles.marginTop} variant='h5'>{this.cropString(this.state.title, 25)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={styles.marginTop} variant='h5'>Tariffa</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={styles.marginTop} variant='h5'>{this.state.fare}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={styles.marginTop} variant='h5'>Ora inizio</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={styles.marginTop} variant='h5'>{this.state.startTime}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={styles.marginTop} variant='h5'>Sala</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={styles.marginTop} variant='h5'>{this.state.screen}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={styles.marginTop} variant='h5'>Posto</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={styles.marginTop} variant='h5'>{this.state.seat}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={styles.marginTop} variant='h5'>Valido</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={styles.marginTop} variant='h5'>{this.state.valid}</Typography>
                    </Grid>
                </Grid>
                <TicketPopup showError={this.state.showError} ticketStatus={this.state.ticketStatus}
                    errorDescription={this.state.statusDescription} onTicketPopupClose={(status) => this.onTicketPopupClose(status)} />
                {DEBUG? <Typography>{JSON.stringify(this.state)}</Typography>: null}
            </>
        )
    }

    formatSeat(seat) {
        return seat != null ? seat.replace(/\d/g, '') + seat.replace(/\D/g, '') : null;
    }

}

export default Validator18Tickets;