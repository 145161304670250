import React from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';

import { getFidelity } from '../../../actions/accesscontrol/customerInputs/namePhoneFidelity/getFidelityData';
import { saveFidelity } from '../../../actions/accesscontrol/customerInputs/namePhoneFidelity/saveFidelity';

const DEBUG=false;

const styles = {
    marginTop: {
        marginTop: '1rem',
    },
}

class NamePhoneFidelity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            barcode: null,
            name: null,
            phone: null,
            dataFromFidelity: false,
            currentState: null,
        };
        this.cleanState = this.state;
        this.focussableInputs = {
            barcode: React.createRef(),
            name: React.createRef(),
            phone: React.createRef(),
        }
    }

    sendUser(compiled) {
        const userData = {
            name: this.state.name,
            phone: this.state.phone,
        }
        this.props.onDataReturned(userData, compiled);
    }

    async onKeyDown(e) {
        if(e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            switch (this.state.currentState) {
                case 'barcode':
                    await this.requestFidelity();
                    break;
                case 'name':
                    this.setState({currentState: 'phone'});
                    break;
                case 'phone':
                    this.sendUser(true);
                    break;
                default:
                    this.setState({currentState: 'barcode'});
                    break;
            }
        }
    }

    async requestFidelity() {
        try {
            const data = await getFidelity(this.state.barcode, this.props.orgId);
            this.setState({
                name: data.name,
                phone: data.phone,
            })
            this.sendUser(true);
        } catch (e) {
            if(e.message === '404' ) {
                this.setState({
                    currentState: 'name',
                })
            }
        }
    }

    async registerFidelity() {
        try {
            const data = {
                name: this.state.name,
                phone: this.state.phone,
            }
            await saveFidelity(this.state.barcode, data, this.props.orgId);
            this.setState({
                dataFromFidelity: true,
            })
        } catch (e) {

        }
    }

    onChange(e, source) {
        const arr = [];
        if(source === 'barcode') arr[source] = e.target.value;
        else arr[source] = e.target.value.toLocaleUpperCase('it-IT');
        this.setState(arr, () => this.sendUser(false));
    }

    onFocus(e, source){
        this.setState({currentState: source})
        this.props.onFocus();
    }

    handleReset() {
        this.setState(this.cleanState);
        this.props.confirmReset();
    }

    componentDidUpdate() {
        if(this.props.resetState === 'full') this.handleReset();
        if(this.props.currentlyActive && !this.state.currentState) this.setState({currentState: 'barcode'});
        if(this.props.currentlyActive && this.state.currentState === 'compiled') this.sendUser();
        if(this.state.currentState === 'send') this.sendUser();
    }

    render() {
        return (
            <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                    <TextField fullWidth
                    variant='outlined'
                    sx={styles.marginTop}
                    value={this.state.barcode || ''}
                    /*onChange={(e) => this.setState({name: e.target.value.toLocaleUpperCase('it-IT')})}*/
                    onChange={(e) => this.onChange(e, 'barcode')}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onMouseDown={() => this.setState({currentState: 'barcode'})}
                    onFocus={(e) => this.onFocus(e, 'barcode')}
                    inputRef={input => input && this.props.currentlyActive && this.state.currentState === 'barcode' && !(document.activeElement === input)? input.focus():null}
                    label='Tessera' />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button variant="contained"
                    fullWidth
                    sx={styles.marginTop}
                    disabled={this.state.dataFromFidelity || !this.state.barcode || !this.state.name || !this.state.phone}
                    onClick={async () => await this.registerFidelity()}
                    >
                        Salva
                    </Button>
                </Grid>
            </Grid>
            
            
                <TextField fullWidth
                    variant='outlined'
                    sx={styles.marginTop}
                    value={this.state.name || ''}
                    /*onChange={(e) => this.setState({name: e.target.value.toLocaleUpperCase('it-IT')})}*/
                    onChange={(e) => this.onChange(e, 'name')}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onMouseDown={() => this.setState({currentState: 'name'})}
                    onFocus={(e) => this.onFocus(e, 'name')}
                    inputRef={input => input && this.props.currentlyActive && this.state.currentState === 'name' && !(document.activeElement === input)? input.focus():null}
                    label='Nome e Cognome' />
                <TextField fullWidth
                    variant='outlined'
                    sx={styles.marginTop}
                    value={this.state.phone || ''}
                    onChange={(e) => this.onChange(e, 'phone')}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onMouseDown={() => this.setState({currentState: 'phone'})}
                    onFocus={(e) => this.onFocus(e, 'phone')}
                    inputRef={input => input && this.props.currentlyActive && this.state.currentState === 'phone' && !(document.activeElement === input)? input.focus():null}
                    label='Telefono' />
                
                {DEBUG? <Typography>{JSON.stringify(this.state)}</Typography>: null}
                
            </>
        )
    }

}

export default NamePhoneFidelity;