import axios from 'axios';

import { validator } from '../../../../settings.json';


async function getTicket(qr, theatreId) {
    const address = (validator.api18tickets.endpoint).replace('{theatreId}', theatreId);
    const ticketData = retreiveTicketData(qr);
    try {
        const projectionData = await axios.post(address, {
            id: ticketData[0],
            kind: ticketData[1],
            code: ticketData[2],
        });
        return projectionData.data;

    } catch(e) {
        return e.response.data
    }
    
}

function retreiveTicketData(qr) {
    return qr.split("|");
}

export {
    getTicket,
};