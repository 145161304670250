import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../../settings.json';


async function deleteFidelity(barcode, organizer) {
    try {
        await axios.delete(`${api.endpoint}/organizers/${organizer}/fidelity/${barcode}`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` } });
        return true;
    } catch (e) {
        if (e.response.status === 401) throw new Error(-1);
        if (e.response.status === 403) throw new Error(-2);
    }

}

export {
    deleteFidelity,
}