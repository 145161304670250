import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../../settings.json';


async function getCinemas() {
    try {
        const rawCinemas = await axios.get(`${api.endpoint}/cinemas`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` }});
        const cinemas = rawCinemas.data.map(cinema => {return { id: cinema.id, name: cinema.name }});
        return cinemas;
    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
        if(e.response.status === 403) throw new Error(-2);
    }
    
}

export {
    getCinemas,
}