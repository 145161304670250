import React from 'react';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { Redirect, Switch, Route, Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit, Visibility } from '@mui/icons-material';

import CinemaSelector from './CinemaSelector';
import { getProjections } from '../../../actions/controlpanel/projections/getProjections';
import { groups } from '../../../settings.json'

import ViewPanel from './view/ViewPanel';

const DEBUG = false;

class ProjectionsPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCinema: null,
            projections: null,
            redirectPath: null,
            isAdmin: false,
            loggedIn: Cookies.get('session') ? true : false,
        };
    }

    columns = [
        { field: 'date', headerName: 'Data', flex: 5 },
        { field: 'screen', headerName: 'Sala', flex: 5 },
        {
            field: 'edit',
            headerName: '',
            disableColumnMenu: true,
            hideSortIcons: true,
            disableReorder: true,
            align: 'center',
        },
        {
            field: 'view',
            headerName: '',
            disableColumnMenu: true,
            hideSortIcons: true,
            disableReorder: true,
            align: 'center',
            renderCell: (params) => (<Link to={this.getLink(params.id, 'view')}><Button variant='contained' color='warning' size='small'><Visibility /></Button></Link>
            ),
        },
        /*{
            field: 'print',
            headerName: '',
            disableColumnMenu: true,
            hideSortIcons: true,
            disableReorder: true,
            align: 'center',
            renderCell: (params) => (<Link to={this.getLink(params.id, 'print')}><Button variant='contained' color='error' size='small'><Print /></Button></Link>
            ),
        },*/
    ];

    componentDidMount() {
        // eslint-disable-next-line no-unused-vars
        const isAdmin = groups.controlpanel.readWrite.includes(jwt.decode(Cookies.get('session')).role);

        //TODO Edit
        if(false) {
            this.columns[2].renderCell = (params) => (<Link to={this.getLink(params.id, 'edit')}><Button variant='contained' color='primary' size='small'><Edit /></Button></Link>);
        }
    }

    getLink(projId, action) {
        return `${this.props.match.url}/${projId}/${action}`;
    }

    async componentDidUpdate() {
        if (this.state.selectedCinema && !this.state.projections) {
            this.setState({
                projections: await getProjections(this.state.selectedCinema),
            });
        }
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />

        return (
            <>
                <Switch>
                    <Route path={`${this.props.match.url}/:projId/edit`} />
                    <Route path={`${this.props.match.url}/:projId/view`} component={ViewPanel} />
                    <Route path={`${this.props.match.url}/:projId/print`}>
                        <p>print</p>
                    </Route>
                    <Route path={`${this.props.match.url}/`}>
                        <Container maxWidth='md'>
                            <CinemaSelector onCinemaChange={(cinema) => this.setState({ selectedCinema: cinema })} />
                            {this.state.projections ?
                                <DataGrid
                                    rows={this.state.projections}
                                    columns={this.columns} autoHeight
                                /> :
                                null}
                        </Container>
                    </Route>
                </Switch>

                {DEBUG ? <Typography>{JSON.stringify(this.state)}</Typography> : null}
            </>
        )
    }
}

export default ProjectionsPanel;