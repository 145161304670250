import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../settings.json';


async function getScreens(cinemaId) {
    try {
        const rawCinema = await axios.get(`${api.endpoint}/cinemas/${cinemaId}/screens`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` }});
        const screens = rawCinema.data.map(screen => {return { id: screen.id, name: `${screen.name} (${screen.seats})` }});
        return screens;
    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
        if(e.response.status === 403) throw new Error(-2);
    }
    
}

export {
    getScreens,
}