import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../../../settings.json';

import { stringify18Tickets, stringifyManual } from './seatStringifier';

import { stringifyNamePhone, stringifyNamePhoneFidelity } from './customerStringifier';

const stringifyValidators = {
    '18tickets': stringify18Tickets,
    'manual': stringifyManual
}

const stringifyCustomers = {
    'namePhone': stringifyNamePhone,
    'namePhoneFidelity': stringifyNamePhoneFidelity,
}

async function getCustomers(projId) {
    try {
        const rawCustomers = await axios.get(`${api.endpoint}/projections/${projId}/customers`, { params: {action: 'view'}, headers: { Authorization: `Bearer ${Cookies.get('session')}` } });
        const projectionData = await axios.get(`${api.endpoint}/projections/${projId}`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` }});
        if((!Array.isArray(rawCustomers.data) || !rawCustomers.data.length)) return [{id: -1, name: 'Nessun nominativo', disabled: true}];
        const customers = stringifyArray(rawCustomers.data, projectionData.data.validator.type, projectionData.data.customerInput.type)
        
        return customers;
    } catch (e) {
        console.log(e);
        if (e.response.status === 401) throw new Error(-1);
        if (e.response.status === 403) throw new Error(-2);
    }
}

function stringifyArray(arr, validator, customerInput) {
    return arr.map((projection, index) => {
        const timestamp = new Date(projection.timestamp);
        return {
            id: index,
            seat: stringifyValidators[validator]? stringifyValidators[validator](projection.seat): 'N.D.',
            customer: stringifyCustomers[customerInput]? stringifyCustomers[customerInput](projection.customer): 'N.D.',
            timestamp: timestamp.toLocaleString('it-IT'),
        }
    });
}


export {
    getCustomers,
}