import React from "react";
import { useParams } from "react-router-dom";
import AccessControl from "./AccessControl";

function AccessControlIngressPoint() {
    const { projId } = useParams();
    return <AccessControl projId={projId} />;
}

export default AccessControlIngressPoint;

