import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../settings.json';


async function getProjectionData(projId) {
    try {
        const projectionData = await axios.get(`${api.endpoint}/projections/${projId}`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` }});
        return projectionData.data;

    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
        if(e.response.status === 403) throw new Error(-2);
    }
    
}

export default getProjectionData;