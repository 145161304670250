import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

class TicketPopup extends React.Component {

    showCancelButton() {
        if(this.props.ticketStatus === "online_reduction") {
            return (
                <Button onClick={() => this.props.onTicketPopupClose('deny_online_reduction')} variant="contained" color="secondary">
                            CANCELLA
                </Button>
            )
        }
    }

    showExtraInfo() {
        if(this.props.ticketStatus === "online_reduction") {
            return (
                <DialogContentText>Premendo OK si conferma la riduzione, premendo CANCELLA non si valida il biglietto</DialogContentText>
            )
        }
    }

    closePopup() {
        if(this.props.ticketStatus === "online_reduction") {
            this.props.onTicketPopupClose('confirm_online_reduction');
        } else {
            this.props.onTicketPopupClose(this.props.ticketStatus);
        }
    }

    render() {
        return (
            <>
                <Dialog open={this.props.showError} keepMounted={true} >
                    <DialogTitle >CONTROLLO MANUALE BIGLIETTO</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.props.errorDescription}</DialogContentText>
                        {this.showExtraInfo()}
                    </DialogContent>
                    <DialogActions>
                        <Button
                        onClick={() => this.closePopup()}
                        variant="contained"
                        color="primary">
                            OK
                        </Button>
                        {this.showCancelButton()}
                    </DialogActions>
                </Dialog>
            </>
        )
    }


}

export default (TicketPopup);