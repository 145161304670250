import React from 'react';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';

import { getCinemas } from '../../actions/selector/getCinemas';

class CinemaSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cinemaOpen: false,
            cinemaOptions: [],
        };
    }

    async updateCinemas() {
        this.setState({ cinemaOptions: await getCinemas() });
    }

    setCinema(cinema) {
        this.props.onCinemaChange(cinema? cinema.id: null)
    }

    render() {
        const cinemaLoading = this.state.cinemaOpen && this.state.cinemaOptions.length === 0;
        return <>
            <Autocomplete
                open={this.state.cinemaOpen}
                sx={{alignItems: 'centered'}}
                onOpen={() => {
                    this.setState({ cinemaOpen: true });
                    this.updateCinemas();
                }}
                onClose={() => {
                    this.setState({ cinemaOpen: false });
                }}
                onChange={(event, cinema) => this.setCinema(cinema)}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={this.state.cinemaOptions}
                loading={cinemaLoading}
                loadingText='Caricamento...'
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Cinema"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {cinemaLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </>;
    }


}

export default CinemaSelector;