import React from 'react';
import Cookies from 'js-cookie';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Typography } from '@mui/material';

import NavBar from './NavBar';

import ProjectionsPanel from './projections/ProjectionsPanel';

import jwt from 'jsonwebtoken';
import { groups } from '../../settings.json'
import NavigationDrawer from './NavigationDrawer';
import FidelityPanel from './fidelity/FidelityPanel';


const DEBUG = false;

class ControlPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false,
            currentView: null,
            loggedIn: Cookies.get('session') ? true : false,
        };
    }

    isAuthorized() {
        if(groups.controlpanel.readWrite.includes(jwt.decode(Cookies.get('session')).role)) return 2;
        if(groups.controlpanel.read.includes(jwt.decode(Cookies.get('session')).role)) return 1;
        return 0;
    }

    toggleDrawer() {
        this.setState({drawerOpen: !this.state.drawerOpen});
    }

    render() {
        if (!this.state.loggedIn || !this.isAuthorized()) return <Redirect to='/login' />

        return (
            <>
                <NavBar onToggleDrawer={() => this.toggleDrawer()} />
                <NavigationDrawer open={this.state.drawerOpen} onToggleDrawer={() => this.toggleDrawer()} />

                <Switch>
                    <Route path={`/controlpanel/projections`} component={ProjectionsPanel} />
                    <Route path={`/controlpanel/fidelity`} component={FidelityPanel} />

                    <Route path={`${this.props.match.url}/`}>
                        <Redirect to={`/controlpanel/projections`} />
                    </Route>
                </Switch>

                {DEBUG ? <Typography>{JSON.stringify(this.state)}</Typography> : null}
                {DEBUG ? <Typography>{this.isAuthorized()}</Typography> : null}
            </>
        )
    }
}

export default ControlPanel;