import React from 'react';
import { TextField, Typography } from '@mui/material';

const DEBUG=false;

class ValidatorManual extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seat: null,
            currentState: null,
        };
        this.cleanState = this.state;
    }


    sendSeat(compiled) {
        this.props.onDataReturned({seat: this.state.seat}, compiled);
    }

    onKeyDown(e) {
        if(e.key === 'Enter' || e.key==='Tab') {
            e.preventDefault();
            this.sendSeat(true);
        }
    }

    onChange(e) {
        this.setState({
            seat: e.target.value.toUpperCase(),
        }, () => this.sendSeat(false));
    }

    onFocus(e){
        this.props.onFocus();
    }

    handleReset() {
        this.setState(this.cleanState);
        this.props.confirmReset();
    }

    componentDidUpdate() {
        if(this.props.resetState) this.handleReset();
        if(this.props.currentlyActive && !this.state.currentState) this.setState({currentState: 'stream'})
    }

    render() {        
        return (
            <>
                <TextField fullWidth
                    variant='outlined'
                    sx={{marginTop: '1rem'}}
                    value={this.state.seat || ''}
                    onChange={(e) => this.onChange(e)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onFocus={(e) => this.onFocus(e)}
                    inputRef={input => input && this.props.currentlyActive && this.state.currentState === 'stream'? input.focus():null}
                    label='Posto' />
                
                {DEBUG? <Typography>{JSON.stringify(this.state)}</Typography>: null}
            </>
        )
    }

}

export default ValidatorManual;