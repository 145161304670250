import Cookies from 'js-cookie';
import axios from 'axios';
import { api } from '../../settings.json';

async function doLogin(username, password) {
    try {
        const loginRequest = await axios.post(`${api.endpoint}/users/login`, {
            username, password
        });
        Cookies.set('session', loginRequest.data.token, { sameSite: 'none', secure: true });
        return 1
    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
    }
}

export default doLogin;