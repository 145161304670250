import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../../settings.json';


async function getOrganizers() {
    try {
        const rawOrgs = await axios.get(`${api.endpoint}/organizers`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` }});
        const orgs = rawOrgs.data.filter((org) => org.enabledFeatures.includes('fidelity')).map(org => {return { id: org.id, name: org.name }});
        return orgs;
    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
        if(e.response.status === 403) throw new Error(-2);
        if(e.response.status === 404) return [{id: -1, name: 'Nessun organizzatore abilitato', disabled: true}];
    }
    
}

export {
    getOrganizers,
}