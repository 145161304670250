import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { saveFidelity } from '../../../actions/accesscontrol/customerInputs/namePhoneFidelity/saveFidelity';

class NewFidelityDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            barcode: null,
            name: null,
            phone: null,
        };
    }

    async onNewFidelity() {
        try {
            const userData = {
                name: this.state.name,
                phone: this.state.phone,
            }
            await saveFidelity(this.state.barcode, userData, this.props.orgId);
            this.setState({
                barcode: null,
                name: null,
                phone: null,
            })
        } catch (e) {
            window.alert(`Errore nell'inserimento`);
        }
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
                <DialogTitle>Nuova tessera</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="barcode"
                        label="Codice a barre"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={this.state.barcode || ''}
                        onChange={(e) => this.setState({barcode: e.target.value})}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nome"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={this.state.name || ''}
                        onChange={(e) => this.setState({name: e.target.value})}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="tel"
                        label="Telefono"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={this.state.phone || ''}
                        onChange={(e) => this.setState({phone: e.target.value})}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose()}>Annulla</Button>
                    <Button onClick={() => this.onNewFidelity()}>Aggiungi</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default NewFidelityDialog