import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../../settings.json';


async function getFidelities(cinemaId) {
    try {
        const rawFidelities = await axios.get(`${api.endpoint}/organizers/${cinemaId}/fidelity`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` } });
        if((!Array.isArray(rawFidelities.data) || !rawFidelities.data.length)) return [{id: -1, name: 'Nessuna tessera inserita', disabled: true}];

        const fidelities = rawFidelities.data.map(fidelity => {
            return {
                id: fidelity.id,
                barcode: fidelity.barcode,
                name: fidelity.data.name,
                phone: fidelity.data.phone,
                
            }
        });
        return fidelities;
    } catch (e) {
        if (e.response.status === 401) throw new Error(-1);
        if (e.response.status === 403) throw new Error(-2);
    }

}

export {
    getFidelities,
}