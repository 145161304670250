import React from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardContent, Container, Link, TextField, Typography } from '@mui/material';
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import ErrorPopup from './ErrorPopup';

import doLogin from '../../actions/login/login';

const styles = {
    marginY: css`
        margin: 1rem 0
    `,
}

const loaderCss = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  `;


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loggedIn: Cookies.get('session') ? 1 : 0,
            processing: false,
            error: false,
            errorText: '',
        };
        this.wrapper = React.createRef();
    }

    doUserLogin(endpoint) {
        this.setState({ processing: true });
        doLogin(this.state.username, this.state.password).then(res => {
            this.setState({ processing: false, loggedIn: endpoint });
        }).catch(err => {
            this.setState({ processing: false, error: true, errorText: "Nome utente o password non valida!" });
        });
    }

    render() {
        if (this.state.loggedIn === 1) return <Redirect to='/selector' />
        if (this.state.loggedIn === 2) return <Redirect to='/controlpanel' />

        return (
            <>
                <Container maxWidth='xs'>
                    <Card>
                        <CardContent>
                            <Typography variant='h4' align='center'>LOGIN</Typography>
                            <TextField id='field-username'
                                variant='outlined'
                                sx={styles.marginY}
                                value={this.state.username}
                                onChange={(e) => this.setState({ username: e.target.value })}
                                label='Username/Codice Postazione'
                                fullWidth />
                            <TextField id='field-password'
                                variant='outlined'
                                sx={styles.marginY}
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                type='password' label='Password'
                                fullWidth />
                            <Button
                                variant='contained'
                                css={styles.marginY}
                                color='primary' fullWidth
                                disabled={this.state.processing}
                                onClick={() => this.doUserLogin(1)}>LOGIN</Button>
                            <Typography sx={styles.marginY} align='center'>
                                <Link href="#" onClick={() => this.doUserLogin(2)}>Premi qui per accedere al pannello di amministrazione</Link>
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
                <ScaleLoader color='#3f51b5' loading={this.state.processing} css={loaderCss} size={150} />
                <ErrorPopup ref={this.wrapper} error={this.state.errorText} enabled={this.state.error} onClose={() => this.setState({error: false})} />
            </>
        )
    }


}

export default Login;