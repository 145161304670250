import React from 'react';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';

import doLogout from '../../actions/login/logout';
import { Redirect } from 'react-router';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            loggedIn: Cookies.get('session') ? true : false,
        }
    }

    componentDidMount() {
        const token = jwt.decode(Cookies.get('session'));
        this.setState({ fullName: token.fullName });
    }

    onLogoutClick() {
        doLogout();
        this.setState({loggedIn: false});
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />

        return (
            <>
                <AppBar position='static'>
                    <Toolbar variant='dense' >
                        <Typography variant='h6' sx={{flexGrow: 1}} >{this.state.fullName}</Typography>
                        <Button variant="contained" color="error" onClick={() => this.onLogoutClick()}>
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
            </>
        )
    }


}

export default Login;