import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import './App.css';

import Login from './routes/login/Login';
import Selector from './routes/selector/Selector';
import ControlPanel from './routes/controlpanel/ControlPanel';
import AccessControlIngressPoint from './routes/accesscontrol/AccessControlIngressPoint';
import Cookies from 'js-cookie';

function App() {
  return (
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/selector">
          <Selector />
        </Route>
        <Route path="/controlpanel" component={ControlPanel} />
        <Route path="/accessControl/:projId">
          <AccessControlIngressPoint />
        </Route>
        <Route path="/">
          {!Cookies.get('session')? <Redirect to='/login' />: <Redirect to='/selector' />}
        </Route>
      </Switch>
  );
}

export default App;
