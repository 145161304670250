import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { VideoCameraBack, CardMembership } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const ProjectionsNavComponent = (props) => (
    <ListItem component={Link} to='/controlpanel/projections' key='projections'>
        <ListItemIcon>
            <VideoCameraBack />
        </ListItemIcon>
        <ListItemText primary='Proiezioni' />
    </ListItem>
)

const FidelityComponent = (props) => (
    <ListItem component={Link} to='/controlpanel/fidelity' key='fidelity'>
        <ListItemIcon>
            <CardMembership />
        </ListItemIcon>
        <ListItemText primary='Tessere' />
    </ListItem>
)

export {
    ProjectionsNavComponent,
    FidelityComponent
}