import axios from 'axios';
import Cookies from 'js-cookie';

import { api } from '../../../settings.json';


async function getProjections(cinemaId) {
    try {
        const rawProjections = await axios.get(`${api.endpoint}/cinemas/${cinemaId}/projections`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` } });
        if((!Array.isArray(rawProjections.data) || !rawProjections.data.length)) return [{id: -1, name: 'Nessuna proiezione disponibile', disabled: true}];
        const screens = [];
        for( const projection of rawProjections.data ) {
            if(!(projection.screen in screens)) {
                screens[projection.screen] = await getScreen(cinemaId, projection.screen);
            }
        }
        const projections = rawProjections.data.map(projection => {
            const projDate = new Date(projection.startDate);
            return {
                id: projection.id,
                date: projDate.toLocaleString('it-IT', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}),
                screen: screens[projection.screen].name,
            }
        });
        return projections;
    } catch (e) {
        if (e.response.status === 401) throw new Error(-1);
        if (e.response.status === 403) throw new Error(-2);
    }

}

async function getScreen(cinemaId, screenId) {
    try {
        const rawScreen = await axios.get(`${api.endpoint}/cinemas/${cinemaId}/screens/${screenId}`, { headers: { Authorization: `Bearer ${Cookies.get('session')}` }});
        const screen =  { id: rawScreen.data.id, name: `${rawScreen.data.name} (${rawScreen.data.seats})` };
        return screen;
    } catch(e) {
        if(e.response.status === 401) throw new Error(-1);
        if(e.response.status === 403) throw new Error(-2);
    }
}

export {
    getProjections,
}