import axios from 'axios';
import Cookies from 'js-cookie';

import {
    api
} from '../../../../settings.json';


async function getFidelity(barcode, orgId) {
    try {
        const fidelityData = await axios.get(`${api.endpoint}/organizers/${orgId}/fidelity/${barcode}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get('session')}`
            }
        });
        return fidelityData.data.data;

    } catch (e) {
        if(e.response.status === 404) throw new Error(404);
    }

}

export {
    getFidelity,
};