import React from 'react';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';

import { getOrganizers } from '../../../actions/controlpanel/fidelity/getOrganizers';

class OrgSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orgOpen: false,
            orgOptions: [],
        };
    }

    async updateCinemas() {
        this.setState({ orgOptions: await getOrganizers() });
    }

    setOrg(org) {
        this.props.onOrgChange(org? org.id: null);
        this.setState({orgOpen: false});
    }

    render() {
        const orgLoading = this.state.orgOpen && this.state.orgOptions.length === 0;
        return <>
            <Autocomplete
                open={this.state.orgOpen}
                sx={{alignItems: 'centered'}}
                onOpen={() => {
                    this.setState({ orgOpen: true });
                    this.updateCinemas();
                }}
                onClose={() => {
                    this.setState({ cinemaOpen: false });
                }}
                getOptionDisabled={(option) => option.disabled}
                onChange={(event, org) => this.setOrg(org)}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={this.state.orgOptions}
                loading={orgLoading}
                loadingText='Caricamento...'
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Organizzatore"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {orgLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </>;
    }


}

export default OrgSelector;