import React from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import TopBar from './TopBar';
import CinemaSelector from './CinemaSelector';
import ScreenSelector from './ScreenSelector';
import ProjectionSelector from './ProjectionSelector';
import ProjectionBuilder from './ProjectionBuilder';

import createProjection from '../../actions/selector/createProjection';


const styles = {
    marginY: {
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    centerBox: {
        justifyContent: "center",
        alignItems: "center"
      }
}



class Selector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCinema: null,
            selectedScreen: null,
            selectedProjection: null,
            isNewProjection: false,
            newProjectionDate: null,
            readyToRedirect: false,
            loggedIn: Cookies.get('session') ? true : false,
        };
    }

    async onCreateProjection() {
        const newProjData = await createProjection(this.state.selectedCinema, this.state.selectedScreen, this.state.newProjectionDate);
        this.setState({selectedProjection: newProjData.id, readyToRedirect: true});
    }

    async onSelectProjection() {
        this.setState({readyToRedirect: true});
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />
        if (this.state.readyToRedirect) return <Redirect push to={`/accesscontrol/${this.state.selectedProjection}`} />

        return (
            <>
                <TopBar />

                <Container maxWidth='md' sx={styles.marginY}>
                    <Typography variant='h4' align='center'>Scegli il cinema</Typography>
                    <CinemaSelector onCinemaChange={(cinema) => this.setState({ selectedCinema: cinema })} />
                </Container>
                <Box visibility={this.state.selectedCinema ? 'visible' : 'hidden'}>
                    <Container maxWidth='md' sx={styles.marginY}>
                        <Typography variant='h4' align='center'>Scegli la sala</Typography>
                        <ScreenSelector cinemaId={this.state.selectedCinema} onScreenChange={(screen) => this.setState({ selectedScreen: screen })} />
                    </Container>
                </Box>
                <Box visibility={this.state.selectedScreen ? 'visible' : 'hidden'}>
                    <Container maxWidth='md' sx={styles.marginY}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='h4' align='center'>Scegli la proiezione</Typography>
                                <Grid container spacing={3} sx={styles.centerBox}>
                                    <Grid item xs={12} sm={7}>
                                        <ProjectionSelector cinemaId={this.state.selectedCinema}
                                            screenId={this.state.selectedScreen}
                                            onProjectionChange={(projection) => this.setState({ isNewProjection: false, selectedProjection: projection })} />
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Button size='large' fullWidth variant='contained' color='primary'
                                        disabled={this.state.isNewProjection || !this.state.selectedProjection}
                                        onClick={() => this.onSelectProjection()}>
                                            Avvia
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant='h4' align='center'>Crea una nuova proiezione</Typography>
                                <Grid container spacing={3} sx={styles.centerBox}>
                                    <Grid item xs={12} sm={7}>
                                        <ProjectionBuilder onNewProjection={(date) => this.setState({ isNewProjection: true, newProjectionDate: date })} />
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Button size='large' fullWidth variant='contained' color='primary'
                                        disabled={!this.state.isNewProjection}
                                        onClick={() => this.onCreateProjection()}>
                                            Crea e avvia
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </>
        )
    }


}

export default Selector;