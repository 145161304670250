import React from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import { getProjections } from '../../actions/selector/getProjections';

class ProjectionSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectionOpen: false,
            projectionOptions: [],
        };
    }

    async updateProjections() {
        this.setState({ projectionOptions: await getProjections(this.props.cinemaId, this.props.screenId) });
    }

    setProjection(projection) {
        this.props.onProjectionChange(projection? projection.id: null)
    }

    render() {
        const projectionLoading = this.state.projectionOpen && this.state.projectionOptions.length === 0;
        return <>
            <Autocomplete
                open={this.state.projectionOpen}
                onOpen={() => {
                    this.setState({ projectionOpen: true });
                    this.updateProjections();
                }}
                onClose={() => {
                    this.setState({ projectionOpen: false });
                }}
                onChange={(event, screen) => this.setProjection(screen)}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => option.disabled}
                options={this.state.projectionOptions}
                loading={projectionLoading}
                loadingText='Caricamento...'
                disabled={!(this.props.cinemaId && this.props.screenId)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Proiezione"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {projectionLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
                    

        </>;
    }


}

export default ProjectionSelector;