import React from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import TopBar from './TopBar';

import Validator18Tickets from './validators/18tickets/Validator18Tickets';
import ValidatorManual from './validators/manual/ValidatorManual';

import NamePhone from './userInputs/NamePhone';
import NamePhoneFidelity from './userInputs/NamePhoneFidelity';

import getProjectionData from '../../actions/accesscontrol/getProjectionData';
import saveTicketData from '../../actions/accesscontrol/saveTicketData';
import { styled } from '@mui/system';

import { plugins } from '../../settings.json'
import { getGreenPassStatus } from './utils';

const DEBUG = false;

const VeryBigButton = styled(Button)(({ theme }) => ({
    marginTop: '1rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
}));

class AccessControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cinema: null,
            validator: null,
            customerInput: null,
            startDate: null,
            seat: null,
            customer: null,
            resetState: false,
            activeValidator: null,
            activeCustomerInput: null,
            currentState: null,
            loggedIn: Cookies.get('session') ? true : false,
        };
    }

    async componentDidMount() {
        const projData = await getProjectionData(this.props.projId);
        this.setState({
            cinema: projData.cinema,
            validator: projData.validator,
            customerInput: projData.customerInput,
            plugins: projData.plugins || null,
            startDate: projData.startDate,
            currentState: 'seat',
        });
        this.defineValidatorInUse();
        this.defineCustomerInputInUse();

        document.addEventListener('keydown', this.handleKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        switch (e.key) {
            case 'Escape':
                this.resetPageState();
                break;

            default:
                break;
        }
    }

    onSeatReturned(data, compiled) {
        this.setState({
            seat: data
        })
        if(compiled) {
            if (this.state.customer) this.setState({ currentState: 'review' });
            else this.setState({ currentState: 'customer' });
        }
    }

    onCustomerReturned(data, compiled) {
        this.setState({
            customer: data,
        });
        if(compiled) {
            this.setState({ currentState: 'review' });
        }
    }

    resetPageState(ticketOnly) {
        this.setState({
            resetState: ticketOnly ? 'ticketOnly' : 'full',
            seat: null,
            currentState: 'seat'
        });
        if (!ticketOnly) this.setState({ customer: null })
    }

    handleConfirmedReset() {
        this.setState({
            resetState: false,
        });
    }

    setCurrentState(state) {
        this.setState({ currentState: state })
    }

    defineValidatorInUse() {
        switch (this.state.validator.type) {
            case '18tickets':
                this.setState({ activeValidator: Validator18Tickets });
                break;
            case 'manual':
                this.setState({ activeValidator: ValidatorManual });
                break;
            default:
                this.setState({ activeValidator: null });
        }
    }

    defineCustomerInputInUse() {
        switch (this.state.customerInput.type) {
            case 'namePhone':
                this.setState({ activeCustomerInput: NamePhone });
                break;
            case 'namePhoneFidelity':
                this.setState({ activeCustomerInput: NamePhoneFidelity });
                break;
            default:
                this.setState({ activeCustomerInput: null });
        }
    }

    getPlugin(reqPlugin) {
        if(!this.state.plugins) return null;
        const plugin = this.state.plugins[reqPlugin];
        if(!plugin) return null;
        return plugin;
    }

    async sendData(e) {
        if (e) e.preventDefault();
        if (this.state.seat && this.state.customer) {
            try {
                await saveTicketData(this.props.projId, this.state.seat, this.state.customer);
                this.resetPageState(true);
            } catch (e) {
                console.log(e);
            }
        }
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />

        const ActiveValidator = this.state.activeValidator;
        const ActiveCustomerInput = this.state.activeCustomerInput;

        return (
            <>
                <TopBar projStartDate={this.state.startDate} />

                <Container maxWidth='lg'>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            {this.state.activeValidator ? <ActiveValidator theatreId={this.state.validator ? this.state.validator.theatreId : null}
                                onDataReturned={(seat, compiled) => this.onSeatReturned(seat, compiled)}
                                resetState={this.state.resetState}
                                currentlyActive={this.state.currentState === 'seat'}
                                onFocus={() => this.setState({currentState: 'seat'})}
                                confirmReset={() => this.handleConfirmedReset()} /> : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.state.activeCustomerInput ? <ActiveCustomerInput
                                orgId={this.state.customerInput.organizer || null}
                                onDataReturned={(userData, compiled) => this.onCustomerReturned(userData, compiled)}
                                resetState={this.state.resetState}
                                currentlyActive={this.state.currentState === 'customer'}
                                onFocus={() => this.setState({currentState: 'customer'})}
                                confirmReset={() => this.handleConfirmedReset()} /> : null}
                            <VeryBigButton variant='contained' color='primary' size='large' type='button' fullWidth
                                disabled={!this.state.seat || !this.state.customer}
                                onKeyDown={(e) => e.key === 'Enter' ? this.sendData() : null}
                                onMouseUp={(e) => this.sendData(e)}
                                ref={input => this.state.currentState === 'review' && input ? input.focus() : null}>
                                Invia
                            </VeryBigButton>
                            <VeryBigButton variant='contained' color='error' size='large' type='button' fullWidth
                                onClick={() => this.resetPageState()}>
                                Cancella
                            </VeryBigButton>
                            {
                                getGreenPassStatus(this.state.plugins)?
                                <iframe title="Verifica Green Pass" src={plugins.greenpass.endpoint} style={{width: '100%', marginTop: '1rem'}} /> :
                                null
                            }
                            
                        </Grid>
                    </Grid>
                </Container>

                {DEBUG ? <Typography>{JSON.stringify(this.state)}</Typography> : null}
            </>
        )
    }
}

export default AccessControl;