function stringify18Tickets(entry) {
    return `${entry.seat} (${entry.qr || 'N.D.'})`;
}

function stringifyManual(entry) {
    return entry.seat;
}

export {
    stringify18Tickets,
    stringifyManual,
}