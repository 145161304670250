import React from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router';
import { Drawer, List } from '@mui/material';
import { ProjectionsNavComponent, FidelityComponent } from './NavigationComponents';

class NavigationDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectAction: null,
            loggedIn: Cookies.get('session') ? true : false,
        }
    }

    onSelection(redirect) {
        this.setState({ redirectAction: redirect });
    }

    render() {
        if (!this.state.loggedIn) return <Redirect to='/login' />
        if (this.state.redirectAction) return <Redirect to={`${this.state.redirectAction}`} />

        return (
            <>
                <Drawer
                    anchor='left'
                    open={this.props.open}
                    onClose={() => this.props.onToggleDrawer()}
                >
                    <List>
                        <ProjectionsNavComponent onClick={() => this.onSelection('projections')} />
                        <FidelityComponent onClick={() => this.onSelection('fidelity')} />
                    </List>
                </Drawer>
            </>
        )
    }


}

export default NavigationDrawer;